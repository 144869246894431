@import "settings";

.app-button {
  .mdc-button__label {
    max-width: 100%;
    display: flex;
    align-items: center;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block !important;
      width: 100%;
      margin: 0 8px;
      text-align: center;
    }
  }

  &.mdc-button:disabled,
  &.mat-mdc-button[disabled] {
    pointer-events: auto !important;
  }
}

.mat-mdc-icon-button {
  padding: 0 !important;
  min-width: 0;
  width: 40px !important;
  height: 40px !important;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex !important;

  .mat-icon svg {
    width: 100% !important;
    height: 100% !important;
  }
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-stroked-button,
.mat-mdc-flat-button,
.mat-mdc-flat-button,
.mat-mdc-raised-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  white-space: nowrap;
  letter-spacing: normal;
  word-spacing: normal;
  padding: 0 16px;
  display: inline-block;
  @include mat-mdc-font;

  .mat-icon {
    margin-right: 0 !important;

    svg {
      width: auto;
      height: auto;
    }
  }
}

.mat-mdc-flat-button,
.mat-mdc-raised-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  &.mat-primary {
    background-color: $primary !important;
    color: $white !important;
  }

  &.mat-accent {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.mat-mdc-button {
  &.mat-primary:not(:disabled) {
    color: $primary !important;
  }
  &.mat-primary:disabled {
    color: rgba(0, 0, 0, 0.26) !important;
  }

  &.grid-button-save {
    height: 38px;
    font-weight: $fw-semi-bold;
  }

  &.btn--catskill:hover .mdc-button__ripple::before {
    opacity: 0.1 !important;
  }

  .mdc-button__ripple::before {
    transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1), background-color 200ms cubic-bezier(0.35, 0, 0.25, 1);
  }
}

.delete-non-booking-event-modal,
.delete-meeting-event-modal {
  .mat-mdc-button.btn--inline {
    border: none !important;
    box-shadow: none !important;
    font-size: 16px;
    margin-top: 16px;
  }
}
