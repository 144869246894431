@import "settings";

.mat-mdc-menu-content {
  padding: 0 !important;

  .mat-mdc-menu-item {
    height: 48px;
    min-height: auto !important;
    justify-content: flex-end;

    .mat-mdc-menu-item-text {
      display: flex;
      font-family: Inter, sans-serif !important;
      font-size: 14px !important;
      font-weight: unset !important;
      @include normal-spacing;
      @include text-overflow-ellipsis;

      span {
        @include text-overflow-ellipsis;
      }
    }

    svg.mat-mdc-menu-submenu-icon polygon {
      display: none;
    }
  }
}

.mat-mdc-menu-panel {
  min-height: auto;

  .mat-mdc-menu-content {
    .add-action-submenu-item {
      &:first-child {
        margin-top: 0.25rem;
      }
      &:last-child {
        margin-bottom: 0.25rem;
      }
    }
  }
}

.mat-mdc-menu-panel.inactive-company {
  min-height: auto;
}

.mdc-menu-surface.mat-mdc-select-panel,
div.mat-mdc-select-panel {
  margin: 0;
}

.hierarchical-wrapper.mat-mdc-menu-panel {
  .mat-mdc-menu-content .mat-mdc-menu-item {
    justify-content: flex-start !important;

    .mat-mdc-menu-item-text {
      font-size: 16px !important;
      font-family: Inter, sans-serif !important;
      font-weight: $fw-medium !important;
    }
  }
}
