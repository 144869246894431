@import "settings";

.sc-card {
  border-radius: 8px;
  padding: 32px;
  background-color: $white;
  box-shadow: 0 15px 16px 0 rgba(60, 66, 87, 0.08), 0 2px 8px 0 rgba(0, 0, 0, 0.1);

  &.sc-card-grey {
    background-color: $catskill-white;
    box-shadow: none;
  }

  &.sc-card-dark-grey {
    background-color: $dark-mystic;
    box-shadow: none;
  }

  &.sc-card-small {
    padding: 16px;
  }
}
