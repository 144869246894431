@import "settings";

.mat-mdc-form-field {
  display: inline-block !important;
  position: relative !important;
  font-family: Inter, sans-serif !important;
  letter-spacing: normal !important;

  .mat-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 10px 0 0;
  }
}

.mat-mdc-form-field.mat-form-field-appearance-outline {
  .mat-mdc-text-field-wrapper {
    padding: 0 0 10px !important;

    textarea.mat-mdc-input-element.cdk-textarea-autosize {
      max-height: 200px !important;
      min-height: 75px !important;

      &::placeholder {
        color: rgba($brand-black, 0.3) !important;
      }
    }

    .mat-mdc-form-field-flex {
      padding: 0 0.75em 0 0.75em;
    }
  }

  .mat-mdc-form-field-infix {
    margin: 0;
    border-width: 0;
    min-height: unset;
  }

  &:not(.mat-mdc-form-field-disabled):not(.mat-form-field-invalid):not(.mat-form-field-name):not(.mat-focused) {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
      border-color: $mischka !important;
    }
  }

  .mdc-notched-outline__notch {
    display: none;
  }

  &.mat-form-field-invalid {
    //.mdc-notched-outline__leading,
    //.mdc-notched-outline__trailing,
    &.mat-focused .mat-mdc-input-element {
      border-color: $mischka !important;
    }
  }

  &.mat-focused:not(.mat-form-field-invalid) {
    .mdc-notched-outline__trailing,
    .mdc-notched-outline__leading {
      border-color: $primary !important;
    }
  }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-color: $mischka !important;
}

.app-form-field.mat-mdc-form-field.mat-form-field-appearance-outline {
  .mat-mdc-text-field-wrapper {
    padding-bottom: 0;
  }

  .mat-mdc-form-field-infix {
    margin: 0;
    padding: 11px 0 10px 0;
    border: none;
    border-width: 0;
  }

  .mdc-notched-outline {
    height: 40px;
    top: 0;
  }

  .mdc-notched-outline__leading {
    border-left: 1px solid $mischka;
  }

  .mdc-notched-outline__trailing {
    border: 1px solid $mischka;
    border-left: none;
  }

  &:not(.mat-form-field-disabled):not(.mat-form-field-invalid) .mdc-notched-outline {
    color: $mischka;
  }

  &.mat-form-field-invalid {
    .mdc-notched-outline__leading {
      border-left: 1px solid $scarlet !important;
    }

    .mdc-notched-outline__trailing {
      border: 1px solid $scarlet !important;
      border-left: none !important;
    }

    .mdc-notched-outline {
      color: $scarlet;
    }

    &.mat-focused .mat-mdc-input-element {
      color: $secondary;
    }
  }

  &.mat-focused:not(.mat-form-field-invalid) {
    .mdc-notched-outline {
      color: $primary;
    }

    .mdc-notched-outline__leading {
      border-left: 2px solid $primary;
    }

    .mdc-notched-outline__trailing {
      border: 2px solid $primary;
      border-left: none;
    }
  }
}
