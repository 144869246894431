@import "colors";

.bg-icon {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-size-14 {
  width: 14px;
  height: 14px;
  min-width: 14px;
}

.icon-size-16 {
  width: 16px;
  height: 16px;
}

.icon-size-24 {
  width: 24px;
  height: 24px;
}

.icon-size-32 {
  width: 32px;
  height: 32px;
}

.icon-size-64 {
  width: 64px;
  height: 64px;
}

.icon-size-width-64 {
  width: 64px;
}

.icon-number {
  background-image: url("../assets/icons/number.svg");
}

.icon-phone {
  background-image: url("../assets/icons/landline.svg");
}

.icon-billing-address {
  background-image: url("../assets/icons/billing.svg");
}

.icon-email {
  background-image: url("../assets/icons/envelope.svg");
}

.icon-address {
  background-image: url("../assets/icons/address.svg");
}

.icon-location {
  background-image: url("../assets/icons/location.svg");
}

.icon-instagram {
  background-image: url("../assets/icons/instagram.svg");
}

.icon-facebook {
  background-image: url("../assets/icons/facebook.svg");
}

.icon-x {
  background-image: url("../assets/icons/x.svg");
}

.icon-tiktok {
  background-image: url("../assets/icons/tiktok.svg");
}

.icon-info {
  background-color: $brand-black;
  mask-image: url("../assets/icons/tooltip/info.svg");
  mask-size: cover;
}

.mat-icon {
  overflow: visible !important;
}
