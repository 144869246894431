@import "settings";

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-mdc-tab-links {
  & > div {
    margin-bottom: -4px;
  }

  .mdc-tab {
    display: inline-flex !important;
    flex-shrink: 0 !important;
    flex-grow: 0 !important;
    @include mat-mdc-font;
    @include normal-spacing;

    .mdc-tab__content {
      min-width: 30px;
    }
  }

  .mat-mdc-tab-link .mdc-tab__text-label {
    color: rgba(0, 0, 0, 0.87);
  }

  .mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label {
    color: $primary !important;
  }

  .mat-mdc-tab-link .mdc-tab-indicator__content--underline {
    border-color: $primary !important;
  }
}
.sc-tab-group {
  &.sc-tab-group-without-opacity {
    .mat-tab-label {
      opacity: 1;
    }
  }
  &.sc-tab-group-active-black {
    .mdc-tab--active .mdc-tab__text-label {
      color: $brand-black !important;
      font-weight: $fw-bold;
    }
  }

  .mat-tab-label {
    color: $gray-chateau;
    font-weight: $fw-medium;
    font-size: 16px;
  }

  .mdc-tab--active {
    color: $primary !important;
    opacity: 1;
  }
}

.mat-mdc-tab-link-container,
.sc-mdc-tab-group {
  .mdc-tab {
    height: 42px !important;
    line-height: 42px;
    flex-grow: 0 !important;
    padding: 0;
    margin: 0 15px;
    min-width: 30px;
    @include mat-mdc-font;
    @include normal-spacing;

    &:first-child {
      margin-left: 40px;
    }

    .mdc-tab__content .mdc-tab__text-label {
      color: $secondary;
    }

    &.mdc-tab--active .mdc-tab__content .mdc-tab__text-label {
      color: $primary !important;
    }

    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
      border-color: $primary !important;
    }

    .mdc-tab__ripple::before {
      display: none !important;
    }
  }

  &.sc-tab-group-active-black {
    .mdc-tab--active {
      margin: 0;
      padding: 0 24px;
      height: 48px !important;

      .mdc-tab__content .mdc-tab__text-label {
        color: $brand-black !important;
        font-size: 16px;
        font-weight: $fw-bold;
      }
    }
  }
}

.mat-mdc-tab-link {
  padding: 0;
  min-width: 30px !important;

  &:not(:first-of-type) {
    margin: 0 15px;
  }

  &.mat-mdc-tab-link:first-child {
    margin-right: 15px !important;
  }

  &.mat-mdc-tab-link:only-child {
    margin-right: 0 !important;
  }
}
