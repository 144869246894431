@import "settings";

.sc-select {
  select {
    border: rem(1px) solid $mischka;
    border-radius: rem(4px);
    font-size: rem(16px);
    padding: rem(0 12px);
    line-height: rem(48px);
    height: rem(48px);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;

    &.small {
      line-height: rem(40px);
      height: rem(40px);
    }

    &.placeholder {
      color: rgba($brand-black, 0.3);
    }
  }

  label {
    font-size: rem(16px);
    margin-bottom: rem(12px);
    line-height: rem(19px);
    font-weight: bold;

    &.small {
      font-size: rem(14px);
      line-height: rem(17px);
      margin-bottom: rem(3px);
    }
  }
}

.app-select.ng-select {
  &.ng-select-focused > .ng-select-container,
  &.ng-select-opened > .ng-select-container {
    border: 2px solid $primary;
    box-shadow: none;

    .ng-value-container {
      padding-left: 9px;
    }

    .ng-arrow-wrapper {
      padding-right: 4px;
    }
  }

  &.ng-invalid.ng-touched .ng-select-container {
    border-color: $scarlet;
    box-shadow: none;
  }

  &.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          font-size: 16px;
          font-weight: $fw-medium;
        }
      }
    }
  }

  .ng-select-container {
    min-height: 40px;
    border: 1px solid $mischka;
    border-radius: 4px !important;

    &:hover {
      box-shadow: none;
    }

    .ng-value-container {
      .ng-placeholder {
        line-height: 28px;
        font-weight: $fw-medium;
        color: rgba($brand-black, 0.3);
      }
    }
  }

  .ng-input {
    margin-top: -1px;

    input {
      font-family: "Inter", sans-serif !important;
      font-size: 16px;
      min-height: 28px;
      font-weight: $fw-medium;
    }
  }

  .ng-dropdown-panel {
    border-radius: 8px;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);

    &.ng-select-bottom {
      margin-top: 0;
    }
    &.ng-select-top {
      margin-bottom: 0;
    }

    .ng-dropdown-panel-items {
      border-radius: 8px;
      padding: 8px 0;
    }

    .ng-option {
      font-size: 16px;
      font-weight: $fw-medium;
      padding: 10px 16px;

      &:hover:not(.ng-option-disabled),
      &.ng-option-marked:not(.ng-option-disabled),
      &.ng-option-selected:not(.ng-option-disabled) {
        background: $primary;
        color: $white;
        margin: 0 8px;
        padding: 10px 8px;
        border-radius: 4px;
      }

      &.ng-option-disabled {
        background: none;
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-focused,
    &.ng-select-opened {
      .ng-select-container {
        .ng-value-container {
          /* Workaround to prevent changing height and placeholder position, because border-size is changing on focus */
          padding-top: 2px;
          padding-left: 2px;
          margin-bottom: -1px;

          .ng-placeholder {
            margin-top: -1px;
          }
        }
      }
    }

    .ng-select-container {
      .ng-value-container {
        padding-top: 3px;
        padding-left: 3px;

        .ng-input,
        .ng-placeholder {
          padding-left: 7px;
        }

        .ng-value {
          display: flex;
          flex-direction: row-reverse;
          background-color: rgba($primary, 0.2);
          color: $primary;
          font-size: 14px;
          font-weight: $fw-bold;
          padding: 5px 8px;
          margin-bottom: 3px;
          margin-right: 3px;

          .ng-value-label {
            padding: 1px 4px;
          }

          .ng-value-icon {
            padding: 1px 4px;
            border: 0;
            opacity: 0.4;
            font-size: 0;
            width: 16px;
            background: url("../assets/icons/close.svg") no-repeat center !important;
            background-size: 100%;

            &:hover {
              opacity: 1;
            }

            mat-icon {
              width: 8px;
              height: 8px;
            }
          }
        }
      }
    }

    &.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
      border: 0;
    }
  }

  /* In case if you need to hide 'clear all' button but leave ability to remove items one by one via backspace */
  &.hide-clear-button {
    .ng-clear-wrapper {
      display: none;
    }
  }

  &.hide-arrow-icon {
    .ng-arrow,
    .ng-arrow-wrapper {
      display: none;
    }
  }

  &.search-icon {
    &.ng-select-focused > .ng-select-container,
    &.ng-select-opened > .ng-select-container {
      .ng-arrow-wrapper {
        margin-right: 15px;
      }
    }

    .ng-arrow {
      display: none;
    }

    .ng-arrow-wrapper {
      height: 16px;
      width: 16px;
      background-image: url("../assets/icons/search.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      margin-right: 16px;
      margin-left: 10px;
    }
  }

  &.spinner-icon {
    &.ng-select-focused > .ng-select-container,
    &.ng-select-opened > .ng-select-container {
      .ng-arrow-wrapper {
        margin-right: 5px;
      }
    }
    .ng-arrow {
      display: none;
    }
    .ng-arrow-wrapper {
      height: 16px;
      width: 16px;
      background-image: url("../assets/icons/spinner.svg");
      background-repeat: no-repeat;
      background-size: auto;
      margin-right: 6px;
      margin-left: 10px;
    }
  }

  &.tag-text-overflow-ellipsis {
    .ng-value-container {
      max-width: 100%;
    }

    .ng-value {
      max-width: calc(100% - 3px);
    }

    .ng-value-label {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ng-input {
      min-width: 100px;
    }
  }

  &.modal-multi-select {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          max-width: 415px;

          .ng-value-label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.app-select.ng-dropdown-panel,
.app-select.ng-select .ng-dropdown-panel {
  border-radius: 8px;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);

  &.ng-select-bottom {
    margin-top: 0;
  }
  &.ng-select-top {
    margin-bottom: 0;
  }

  .ng-dropdown-panel-items {
    border-radius: 8px;
    padding: 8px 0;

    .scrollable-content {
      padding: 8px 0;
    }
  }

  .ng-option,
  .ng-option.ng-option-child {
    font-size: 16px;
    font-weight: $fw-medium;
    padding: 10px 16px;

    &:hover:not(&.ng-option-disabled),
    &.ng-option-marked:not(.ng-option-disabled),
    &.ng-option-selected:not(.ng-option-disabled),
    &.ng-option-selected.ng-option-marked:not(.ng-option-disabled) {
      background: $primary;
      color: $white;
      margin: 0 8px;
      padding: 10px 8px;
      border-radius: 4px;
    }

    &.ng-option-disabled {
      background: none;
    }

    .ng-tag-label {
      font-size: 100%;
    }
  }
}

.app-select.app-select-selected-gray.ng-select {
  .ng-select-container {
    .ng-value-container {
      .ng-value {
        background-color: $mischka;
        color: $brand-black;
        .ng-value-icon {
          color: $dark-grayish-blue;
        }
      }
    }
  }
}

.app-select.app-select-selected-gray.ng-dropdown-panel,
.app-select.app-select-selected-gray.ng-select .ng-dropdown-panel {
  .ng-option.ng-option-selected:not(.ng-option-disabled) {
    background: none;
    color: rgba($disable-gray, 0.5);
  }
  .ng-option-disabled.ng-optgroup.ng-option-selected {
    background: none;
  }
}

.app-select.ng-dropdown-panel,
.app-select.ng-select .ng-dropdown-panel {
  .ng-optgroup {
    margin-left: 6px;
    margin-right: 6px;
    font-size: 14px;
  }
}

// This is not a spinner, need to change icon name
.app-select.app-select-spinner.ng-select.search-icon .ng-arrow-wrapper {
  background-image: url("../assets/icons/spinner.svg");
  background-size: auto;
}

.app-select.ng-select.no-border-radius .ng-select-container {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ng-select-container .ng-value-container .ng-input > input,
.ng-select,
.ng-select-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  cursor: pointer !important;
}

.ng-select.ng-select-opened .ng-select-container,
.ng-dropdown-panel {
  z-index: 1002 !important;
}
