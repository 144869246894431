// Import global settings.
@import "settings";

// Import Bootstrap.
@import "node_modules/bootstrap-4-grid/scss/grid/bootstrap-grid";
@import "node_modules/bootstrap-4-grid/scss/sizing/utilities/sizing";
@import "node_modules/bootstrap-4-grid/scss/text/mixins/deprecate";
@import "node_modules/bootstrap-4-grid/scss/text/mixins/hover";
@import "node_modules/bootstrap-4-grid/scss/text/mixins/text-emphasis";
@import "node_modules/bootstrap-4-grid/scss/text/mixins/text-hide";
@import "node_modules/bootstrap-4-grid/scss/text/mixins/text-truncate";
@import "node_modules/bootstrap-4-grid/scss/text/utilities/text";

// Import Quill.
@import "node_modules/quill/dist/quill.core.css";
@import "node_modules/quill/dist/quill.bubble.css";
@import "node_modules/quill/dist/quill.snow.css";

// Import local styles.
@import "colors";
@import "base";
@import "fonts";
@import "theme";
@import "drag-and-drop";
@import "ag-grid-table";
@import "custom-tooltips";
@import "selectable-menu";
@import "carousel";
@import "mat-form-field";
@import "mat-button";
@import "mat-tab";
@import "mat-menu";
@import "form-grid";
@import "mat-select";
@import "mat_input";
@import "mat-snack-bar";
@import "mat-slide-toggle";
@import "mat-radio-group";
@import "icons";
@import "calendar";
@import "mat-snackbar";
@import "auto-complete";
@import "select";
@import "print-version";
@import "link";
@import "rich-text-editor";

// Import Story Elements styles. Legacy
@import "story-elements/calendar";
@import "story-elements/buttons";
@import "story-elements/input";
@import "story-elements/select-multi";
@import "story-elements/dialog";
@import "story-elements/colors";
@import "story-elements/column-order";
@import "story-elements/card";
@import "story-elements/button-toggle-group";

// Components
@import "components/multiselect";

// tippy.js
@import "tippy.js/themes/light.css";
@import "tippy.js/dist/tippy.css";

// ng-select
@import "@ng-select/ng-select/themes/default.theme.css";

.shb-part-avail {
  &:before {
    content: "";
    background-image: url("./assets/icons/adjust-solid.svg");
    height: 12px;
    width: 12px;
    margin-right: 3px;
    transform: rotateZ(270deg);
  }

  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ffdfb3;
  color: #cb7715;
  border-radius: 201px;
  font-size: 12px;
  height: 20px;
  padding: 2px 8px;
  margin-left: 5px;
}

.period-selector {
  background-color: white !important;
  width: 55px !important;
  top: 50%;
  right: -42% !important;
  padding: 0 !important;

  li {
    &:hover {
      background-color: $mischka;
    }
  }

  &__button {
    color: $primary !important;
  }
}

.period-control {
  &__arrow {
    display: none !important;
  }

  &__button {
    width: 30px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    text-transform: lowercase !important;
    color: $primary !important;

    &:after {
      display: none !important;
    }
  }

  &--active {
    &:after {
      display: none !important;
    }
  }
}

.time-picker-renderer {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.grid-with-divider-menu {
  .ag-row.ag-full-width-row {
    overflow: visible;
    border: 0 !important;
    z-index: 2;
    background: transparent !important;
  }

  .ag-theme-material,
  .ag-root,
  .ag-body-viewport {
    overflow: visible !important;
  }
}

.grid-divider {
  position: absolute;
  top: -3px;
  height: 6px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  mat-divider {
    display: none;
    width: 100%;
    height: 2px;
    border-top: 2px solid $primary;
  }

  button {
    display: none;
    position: absolute;
    background-color: $primary;
    border-radius: 100%;
    box-shadow: 0 2px 4px 0 rgba($primary, 0.24);
    width: 16px;
    height: 16px;
    border: 0;
    color: white;
    padding: 0;
    cursor: pointer;
  }

  &.disabled {
    display: none;
  }

  &:hover:not(&.disabled),
  &.active:not(&.disabled) {
    mat-divider {
      display: block;
    }

    button {
      display: block;
    }
  }
}

.schedule-resize-left-cursor {
  cursor: url(./assets/icons/resize-cursor-left.png), pointer !important;
}

.schedule-resize-right-cursor {
  cursor: url(./assets/icons/resize-cursor-right.png), pointer !important;
}

.grab {
  cursor: move;
}

.ag-dnd-ghost {
  cursor: default !important;
  padding: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  background: transparent !important;
  display: flex;
  overflow: visible !important;

  .ag-dnd-ghost-label {
    box-shadow:
      0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    border-radius: 2px !important;
    background: white !important;
    padding: 0 16px !important;
    align-self: stretch;

    &:empty {
      display: none !important;
    }
  }
}

.ag-dnd-ghost-icon {
  display: none !important;
}

.ag-row-highlight-above::after,
.ag-row-highlight-below::after {
  background-color: $dark-gray !important;
}

.no-drag {
  cursor: default !important;
}

.no-drag-editable {
  pointer-events: auto;
}

.mat-mdc-menu-panel {
  min-width: 0 !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "Small" !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  font-size: 10px;
}

.ql-picker.ql-size .ql-picker-label[data-value=""]::before,
.ql-picker.ql-size .ql-picker-item[data-value=""]::before {
  content: "Normal";
}
.ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "Large" !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "Huge" !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  font-size: 32px;
}

.schedule-height-calculation-container {
  position: absolute;
  white-space: pre-wrap;
  visibility: hidden;
  z-index: -1;
  word-break: break-word;

  p {
    margin: 0 !important;
  }
}

.mat-button.hide-focus-overlay {
  .mat-button-focus-overlay {
    display: none;
  }
}

.mat-pseudo-checkbox {
  display: none !important;
}
