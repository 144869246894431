@import "settings";

.rectangle {
  border-radius: rem(3px);
  width: rem(50px);
  height: rem(50px);

  &--brand-black {
    background-color: $brand-black;
  }

  &--secondary {
    background-color: $secondary;
  }

  &--gray-chateau {
    background-color: $gray-chateau;
  }

  &--mischka {
    background-color: $mischka;
  }

  &--catskill-white {
    background-color: $catskill-white;
  }

  &--primary {
    background-color: $primary;
  }

  &--dodger-blue {
    background-color: $dodger-blue;
  }

  &--caribbean-green {
    background-color: $caribbean-green;
  }

  &--atlantis {
    background-color: $atlantis;
  }

  &--supernova {
    background-color: $supernova;
  }

  &--west-side {
    background-color: $west-side;
  }

  &--scarlet {
    background-color: $scarlet;
  }

  &--hot-pink {
    background-color: $hot-pink;
  }

  &--eminence {
    background-color: $eminence;
  }
}
