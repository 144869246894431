@import "settings";

.mdc-snackbar {
  position: fixed !important;
  right: 24px !important;
  top: 24px !important;
  padding: 0 !important;
  margin: 24px !important;
  height: 52px;
  left: auto !important;
  transform-origin: center;
  max-width: 33vw;
  min-width: 344px;
  border-radius: 4px !important;
  box-sizing: border-box !important;

  .mdc-snackbar__surface {
    background: $white !important;
    box-shadow: none !important;
  }
}
