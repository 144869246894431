// Font configs.
$rem-fallback: false !default;
$rem-px-only: false !default;
$base-font-size: 16px !default;

// Font Weight configs.
$fw-thin: 100;
$fw-extra-light: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 800;
$fw-black: 900;

// Colors.
$white: #fff;
$snow: #fafafa;
$white-opacity-40: #ffffff66;
$wild-sand: #f5f5f5;
$catskill-white: #eff2f7;
$mischka: #d2d6dc;
$mischka-opacity-50: rgba(210, 214, 220, 0.5);
$bright-gray: #eaedf3;
$light-gray: #cecece;
$checkbox-grey: #e0e1e4;
$scheduler-weekend: #f6f8f9;
$grey: #9e9e9e;
$grey-80: #cccccc;
$mystic: #edf0f5;
$dark-mystic: #e5e9ef;
$dusty-gray: #999999;
$secondary: #6b7280;
$secondary-opacity-04: rgba(#6b7280, 0.4);
$secondary-opacity-02: rgba(#6b7280, 0.2);
$solitude: #eaedf2;
$gray-line-grid: #e2e2e2;
$select-drop-down-gray: #f6f6f6;
$logo-color: #6366f1;
$gray-chateau: #9ca3af;
$gray-chateau-opacity-40: rgba(156, 163, 175, 0.4);
$gray-chateau-opacity: #9ca3af29;
$gray-athens: #e1e3e7;
$gray-santas: #b2b2b2;
$lavender: #f0f0f2;
$gray: #757575;
$thunder: #2f2f2f;
$silver: #c0c0c0;
$clock-disabled: #c5c5c5;
$disable-gray: #5b5e5f;
$calendar-next-gray: #d3d7dd;
$line-header-gray: #eaedf3;
$title-gray: #757575;
$unavailable-gray: #cdd1d7;
$dark-gray: #3c4252;
$bubble: #2a303c;
$dark-bubble: #2a303c;
$brand-black: #1a1f36;
$sap-brand: #034386;
$sap-brand-search: #014185;
$sap-brand-ready: #1a237e;
$sap-brand-search-opacity-20: #01418533;
$sap-brand-search-opacity-50: #01418580;
$sap-brand-ready-opacity-20: #1a237e33;
$sap-brand-opacity-10: #03438619;
$brand-black-opacity-50: #1a1f3680;
$brand-black-opacity-48: #1a1f367a;
$brand-black-opacity-10: rgba(#1a1f36, 0.1);
$black: #000000;
$black-opacity-50: rgb(0 0 0 / 50%);
$black-specific: #1a1f36;
$blue: #8799e8;
$blue-02: #c9cff1;
$blue-embossing: #e1e6ef;
$oceanic: #039be5;
$message-blue-bg: #2296f3;
$dodger-blue: #4babff;
$dodger-blue-10: rgba(67, 69, 204, 0.1);
$dodger-sky-10: rgba(75, 171, 255, 0.1);
$text-blue: #2f80ed;
$checkbox-border-blue: #4345cc;
$primary: #5457ff;
$primary-dark: #1314d9;
$primary-light: #edeefe;
$dark-blue: #4f65b1;
$dirty-blue: #3c88cc;
$brand: #254185;
$light-perple: #cccdff;
$robins-egg-blue: #06bcca;
$caribbean-green: #06ca9b;
$caribbean-green-opacity-30: rgba(6, 202, 155, 0.3);
$caribbean-green-opacity-40: rgba(6, 202, 155, 0.4);
$caribbean-green-opacity: #06ca9b29;
$caribbean-green-filter: invert(68%) sepia(87%) saturate(3154%) hue-rotate(122deg) brightness(96%) contrast(95%);
$atlantis: #a0db26;
$supernova-dirty: #cca000;
$supernova: #ffc800;
$west-side: #ff9c0d;
$west-side-dirty: #cc7c0a;
$west-side-dark: #ca7700;
$cinnabar: #e64e3d;
$scarlet: #ff2200;
$mat-error: #f44336;
$scarlet-selection: #ffbcb1;
$scarlet-light: #fee8e5;
$scarlet-opacity: rgba(255, 34, 0, 0.2);
$scarlet-opacity01: rgba(255, 34, 0, 0.1);
$scarlet-border: #e42e00cc;
$red-slider: #e02424;
$flame-pea: #db5041;
$hot-pink: #ff68c5;
$dark-pink: #cc539d;
$eminence: #69277f;
$dark-eminence: #541f65;
$red: #dd2c00;
$red-rich: #f00000;
$red-slider: #e02424;
$red-icon: #f05252;
$citrus: #ffab00;
$green-icon: #0e9f6e;
$green: #00c853;
$dark-green: #04a17c;
$tooltip-background: #141728;
$quill-color: rgba(0, 0, 0, 0.42);
$brand-black-filter: invert(11%) sepia(8%) saturate(4025%) hue-rotate(192deg) brightness(93%) contrast(94%);
$gray-chateau-filter: invert(75%) sepia(14%) saturate(228%) hue-rotate(179deg) brightness(85%) contrast(89%);
$shadow: rgba(0, 0, 0, 0.1);
$tooltip-background: #141728;
$calendar-border-gray: #ccd0d7;
$calendar-border-gray-hour-start: #dee5ed;
$calendar-border-gray-day: #e1e1e1;
$calendar-bg-current-day: #f4f3ff;
$calendar-bg-weekend: #f7f9fa;
$warning-red: #de2222;
$lightgreen: #e6faf5;
$lightblue: #eeeeff;
$lightblue-next: #eef2ff;
$card-hover: #cdd4e2;
$online: #059669;
$ag-grid-highlight: #f5f8ff;
$ag-grid-edit-mode: #edeefd;
$primary-opacity-5: rgba(84, 87, 255, 0.05);
$primary-opacity-10: rgba(84, 87, 255, 0.1);
$primary-opacity-30: rgba(84, 87, 255, 0.3);
$primary-opacity-75: rgba(84, 87, 255, 0.75);
$badge-select: #999999;
$border-standard: 1px solid #e4e7ec;
$border-dashed: 1px dashed #e4e7ec;
$unavailable: #ff22000d;
$not-reserved: #f9fafb;
$not-reserved-text: #9da3ae;
$calendar-confirmed: #f5c0b5;
$calendar-after: #cbccff;
$calendar-hold-after: #c1c5f6;
$calendar-confirmed-after: #bfa3c9;
$dark-mode: #0b0d19;
$dark-mode-border: #010102;
$nonEditableField: #f8f8f8;
$puritan-plum: #d1d8e5;
$light-gray-blue: #e3e8ef;
$light-grayish-blue: #f1f0fe;
$dark-grayish-blue: #6b7280;
$dark-grey: #000633;
$disabled: #f9f9f9;
$description-background: rgba(26, 31, 54, 0.04);

// Breakpoints
$handset: 360px;
$large-handset: 480px;
$tablet: 640px;
$large-tablet: 840px;
$desktop: 960px;
$large-desktop: 1280px;
$x-large-desktop: 1440px;
$xx-large-desktop: 1920px;

//containers

$editable-container-width: 1060px;
$editable-item-container: 600px;
$editable-list-container: 400px;

//shadows:
$edit-shadow: inset $editable-item-container 150px rgba(26, 31, 54, 0.8);

@import "helpers/mixins";
@import "helpers/functions";
@import "helpers/breakpoints";
