@import "settings";

.app-button-toggle-group-tooltip {
  &.mat-mdc-tooltip {
    margin: 5px;
    padding: 3px 16px;
    background: $tooltip-background;
    font-size: 12px;
    position: relative;
    color: $mischka !important;
    max-width: 280px;
    border-radius: 4px;

    .mdc-tooltip__surface {
      color: $mischka !important;
      background: $tooltip-background !important;
      padding-left: 0;
      padding-right: 0;
      line-height: 20px;
      max-width: 238px;
    }
  }

  &.tooltip-multiline {
    white-space: pre-wrap !important;
    max-width: 100vh !important;
  }

  &.tooltip-short-gap {
    padding: 3px 10px !important;
  }

  &.tooltip-short {
    max-width: 200px !important;
    text-align: center;
  }

  &.tooltip-shape {
    overflow: visible;
    &:after {
      content: " ";
      position: absolute;
      left: 50%;
      z-index: 2;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
    }
  }
  &.tooltip-below {
    &:after {
      bottom: 100%;
      border-width: 0 6px 5px 6px;
      border-color: transparent transparent $tooltip-background transparent;
    }
  }
  &.tooltip-above {
    &.tooltip-above-top {
      margin: 0 0 10px;
    }

    &:after {
      top: 100%;
      border-width: 5px 6px 0 6px;
      border-color: $tooltip-background transparent transparent transparent;
    }
  }

  &.multi-line {
    white-space: pre-line;
    max-width: 550px !important;
  }

  &.tooltip-below-transparent-w500 {
    background: $secondary;
    color: $white;
    font-size: 11px;
    font-weight: $fw-semi-bold !important;
    max-width: 500px !important;
    word-break: break-all;
    &:after {
      bottom: 100%;
      border-width: 0 6px 5px 6px;
      border-color: transparent transparent $quill-color transparent;
    }
  }
}

.mat-mdc-tooltip.custom-white-tooltip .mdc-tooltip__surface {
  background-color: $white !important;
  border: 1px solid $mischka !important;
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 $shadow;
  height: 32px;
  color: $secondary !important;
  font-size: 12px;
  line-height: 16px;
  font-weight: $fw-regular;
  padding-top: 7px;
}
