.form-row {
  display: flex;
  margin: 0 -8px 17px;
  flex-wrap: wrap;

  .col {
    margin: 0 10px;
    padding: 0;
  }
}
