@import "settings";

.app-slide-toggle {
  &.mat-mdc-slide-toggle .mdc-label {
    font-family: Inter, sans-serif !important;
    line-height: normal !important;
    letter-spacing: normal !important;
  }

  .mdc-form-field {
    vertical-align: unset !important;
  }

  display: inline-block;
  min-height: 24px;
  max-width: 100%;
  line-height: 24px;

  .mdc-label {
    font-size: 14px;
  }

  .mdc-switch {
    background-color: $blue-embossing !important;
    border-radius: 12px !important;
    width: 28px !important;
    height: 16px !important;
    margin-right: 4px;

    .mdc-switch__ripple {
      display: none;
    }
    .mdc-switch__icons {
      display: none;
    }
    .mdc-switch__shadow {
      display: none;
    }

    .mdc-switch__track::before {
      background-color: $blue-embossing !important;
    }

    .mdc-switch__handle {
      &::before {
        display: none;
      }
      &::after {
        background: $white !important;
        width: 12px !important;
        height: 12px !important;
        border-radius: 10px !important;
        border: none !important;
        margin-left: 2px;
        margin-top: 4px;
        box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
      }
    }

    &--selected {
      background-color: $primary !important;

      .mdc-switch__track::after {
        background-color: $primary !important;
      }

      .mdc-switch__handle {
        &::before {
          display: none;
        }
        &::after {
          background: $white !important;
          width: 12px !important;
          height: 12px !important;
          border-radius: 10px !important;
          border: none !important;
          margin-left: 6px;
          margin-top: 4px;
          box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
        }
      }
    }

    &--disabled {
      .mdc-switch__handle {
        &::after {
          opacity: 1 !important;
        }
      }
    }
  }

  &.mat-accent {
    .mdc-switch {
      &--selected {
        background-color: $hot-pink !important;

        .mdc-switch__track::after {
          background-color: $hot-pink !important;
        }
      }
    }
  }

  &.dark-grey-toggle {
    .mdc-switch {
      background-color: $gray-chateau !important;

      .mdc-switch__track::before {
        background-color: $gray-chateau !important;
      }
    }
  }
}

.app-slide-toggle-description {
  padding-left: 36px;
  font-size: 12px;
  color: $gray-chateau;
  line-height: 18px;
  font-weight: $fw-medium;
}

.app-slide-toggle-disabled {
  opacity: 0.38 !important;

  .mdc-label {
    color: rgba($black, 1) !important;
  }
}
