@import "settings";

// Standard
.mat-mdc-form-field {
  .mat-mdc-input-element {
    font-family: Inter, sans-serif;
    background-color: $white;
    letter-spacing: normal !important;

    &::placeholder {
      color: rgba($brand-black, 0.3) !important;
    }
  }
  .mat-form-field-label {
    color: $mischka;
  }
  .mat-form-field-underline {
    background-color: $mischka;
  }
  .mat-form-field-ripple {
    background-color: $mischka;
  }
  .mat-form-field-required-marker {
    color: $mischka;
  }
}

.mdc-notched-outline__leading {
  border-radius: 4px 0 0 4px !important;
}

.mdc-notched-outline__trailing {
  border-radius: 0 4px 4px 0 !important;
}

.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding: 2px 0 10px 0;
}

.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
  margin: 0 !important;
}

// Standart Hover
.mat-form-field-appearance-outline .mdc-notched-outline {
  color: $mischka;
}
.mat-form-field-appearance-outline .mdc-notched-outline {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__notch {
    border-width: 1px !important;
  }
}

//Focus
.mat-mdc-form-field.mat-focused {
  .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing,
    .mdc-notched-outline__notch {
      border-width: 2px !important;
    }
  }
  .mat-form-field-label {
    color: $brand-black;
  }
  .mat-form-field-required-marker {
    color: $brand-black;
  }
  .mat-mdc-input-element {
    color: $brand-black;
  }
}

//Error
.mat-mdc-form-field.mat-form-field-invalid {
  .mat-mdc-input-element {
    color: $scarlet;
  }
  .mat-form-field-label {
    color: $scarlet;
    .mat-form-field-required-marker {
      color: $scarlet;
    }
  }
  .mat-form-field-ripple {
    background-color: $scarlet;
  }
}
