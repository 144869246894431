@import "settings";

.sc-carousel {
  position: relative;
  user-select: none;

  .sc-carousel-items {
    display: flex;
    margin-left: -8px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1) 0s;

    > * {
      display: flex;
      flex: 0 0 20%;
      min-height: 315px;
      padding: 0 8px;

      @include breakpoint-max(767px) {
        flex: 0 0 100%;
      }

      @include breakpoint(768px) {
        flex: 0 0 50%;
      }

      @include breakpoint(992px) {
        flex: 0 0 25%;
      }

      @include breakpoint(1600px) {
        flex: 0 0 20%;
      }
    }
  }

  .sc-carousel-button-left,
  .sc-carousel-button-right {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 10;
    top: calc(50% - 20px);
    transition: 0.2s;
    background-color: $white;
    box-shadow: 0 1px 4px 0 rgba($black, 0.1) !important;
    border: 1px solid $mischka;

    .mdc-button__label {
      padding: 0;
    }

    mat-icon {
      width: 26px;
      height: 26px;
    }
  }

  .sc-carousel-button-left {
    left: -20px;
  }

  .sc-carousel-button-right {
    right: -20px;
  }
}
