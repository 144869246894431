// generate min-width breakpoint
@mixin breakpoint($point) {
  @media (min-width: $point) {
    @content;
  }
}

// generate max-width breakpoint (use sparingly)
@mixin breakpoint-max($point) {
  @media (max-width: $point) {
    @content;
  }
}

// generate min-height breakpoint
@mixin height-breakpoint($point) {
  @media (min-height: $point) {
    @content;
  }
}

// generate max-height breakpoint (use sparingly)
@mixin height-breakpoint-max($point) {
  @media (min-height: $point) {
    @content;
  }
}
