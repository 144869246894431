@import "settings";

.cdk-drag-preview {
  cursor: grab;
  z-index: 1003 !important;
  box-sizing: border-box;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

  &.visible-preview {
    z-index: 1003 !important;
    display: flex;
    flex-direction: row-reverse;
    background-color: rgba($primary, 0.2);
    color: $primary;
    font-size: 14px;
    font-weight: $fw-bold;
    padding: 5px 8px;
    margin-bottom: 3px;
    margin-right: 3px;

    .ng-value-label {
      padding: 0;
      margin-right: 12px;
    }
    .ng-value-icon {
      display: none;
      width: 0;
    }

    .control-button {
      display: none;
    }
  }
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
