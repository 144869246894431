@import "settings";

html,
body {
  height: 100%;
}

html body {
  margin: 0;
  color: $brand-black !important;
  font-family: "Inter", sans-serif !important;
  font-size: 16px !important;
  line-height: 19px;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

.d-grid {
  display: grid;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
  cursor: default !important;
}

.cursor-default {
  cursor: default;
}

.cursor-table-auto {
  cursor: auto !important;
}

.not-allowed {
  cursor: not-allowed !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.link-gray {
  color: $secondary;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  outline: none;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    background: $secondary;
    transition: all 300ms;
    opacity: 0;
  }

  &:hover {
    &::after {
      height: 1px;
      opacity: 1;
    }
  }
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-sticky {
  position: sticky !important;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.visibility-hidden {
  visibility: hidden;
}

.overflow-visible {
  overflow: visible;
}

.index-999 {
  z-index: 999;
}

.mt-7 {
  margin-top: 7px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-16 {
  margin-top: 16px;
}

.ml-5px {
  margin-left: 5px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-60 {
  margin-left: 60px;
}

.mr-5px {
  margin-right: 5px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-7 {
  margin-bottom: 7px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-22 {
  margin-bottom: 22px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.plr-2 {
  padding-left: 2px;
  padding-right: 2px;
}

.pb-150 {
  padding-bottom: 150px;
}

.border-none {
  border: none;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.warning {
  color: $red-icon;
}

.float-right {
  float: right;
}

.min-w-auto {
  min-width: auto !important;
}

.w-0 {
  width: 0 !important;
}

.h-0 {
  height: 0 !important;
}

.h-32 {
  height: 32px !important;
}

.h-auto {
  height: auto !important;
}

.img-center {
  object-fit: contain;
  object-position: center;
}

.border-round {
  border-radius: 50%;
}

.text-underline {
  text-decoration: underline;
}

.hover-underline {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
.opacity-0 {
  opacity: 0;
}

.opacity-15 {
  opacity: 0.15;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.clip {
  overflow: clip;
}

// Don't use this approach, use .text-overflow instead
.ellipsis-text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-overflow {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}

.two-line-text-overflow {
  display: -webkit-box;
  word-break: break-word;
  white-space: pre-line !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tree-line-text-overflow {
  display: -webkit-box;
  word-break: break-word;
  white-space: pre-line !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Don't use this approach, use .text-overflow instead
.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ag-hide-cell {
  display: none !important;
}

.no-wrap {
  white-space: nowrap;
}

.cdk-global-scrollblock {
  height: auto !important;
}

.ag-horizontal-left-spacer {
  display: none;
}

.min-h-100 {
  min-height: 100%;
}

.min-h-px-32 {
  min-height: 32px !important;
}

.min-vh-33 {
  min-height: 33.3vh !important;
}

.width-fit-content {
  width: fit-content;
}

.warning-message {
  background-color: rgba($scarlet, 0.1);
  font-size: 14px;
  line-height: 17px;
  color: $scarlet;
  padding: 15px 20px;
  border-radius: 8px;
  display: block;
}

.flex-grow-2 {
  flex-grow: 2;
}

app-app-basic-multi-select-renderer,
app-discount-renderer {
  width: 100% !important;
}

.pointer-none {
  pointer-events: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

body.opened .settings-nav {
  left: 316px !important;
}

.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: $primary !important;
}
