@import "settings";

body .mat-mdc-dialog-container {
  padding: 0;
  max-height: 80vh !important;

  .mdc-dialog__surface {
    position: static;
  }
}

body .cdk-global-overlay-wrapper .cdk-overlay-pane {
  position: relative !important;
}

.cdk-global-scrollblock {
  overflow: initial !important;
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0 !important;
  }

  .cdk-overlay-container {
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    background: rgba(240, 243, 246, 0.9);
  }
}

.cdk-overlay-container {
  z-index: 1002 !important;

  .cdk-overlay-backdrop {
    &:not(:first-of-type) {
      opacity: 0;
    }
  }
}

.edit-contact-person-dialog,
.edit-contact-company-dialog {
  .mat-mdc-dialog-container {
    background-color: $blue-embossing;
    border-radius: 8px;

    .mdc-dialog__surface {
      background-color: transparent !important;
    }
  }
}

.sap-send-dialog {
  .mat-mdc-dialog-container {
    border-radius: 16px;
  }
}

.create-booking-or-event {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    height: 790px !important;
  }

  .dialog-content {
    overflow: visible !important;
  }

  .sticky-dialog-content {
    margin: 0 -2.5rem !important;
    padding: 2.5rem 2.5rem 1rem 2.5rem !important;
  }

  .dialog-close-btn {
    right: 2.5rem !important;
  }
}

.short-paragraph {
  .dialog-content {
    margin: 0 -10px !important;
  }
}

.cdk-overlay-pane.default-overlay-container {
  background: $white;
  border-radius: 8px;
  box-shadow: 0 15px 16px 0 rgba(60, 66, 87, 0.08), 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important;
}

//.dialog-close-btn {
//  .mat-icon {
//    margin-top: 6px;
//  }
//}
