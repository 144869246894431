@import "settings";

@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-material.css";

.ag-layout-auto-height .ag-center-cols-viewport,
.ag-layout-auto-height .ag-center-cols-container,
.ag-layout-print .ag-center-cols-viewport,
.ag-layout-print .ag-center-cols-container {
  min-height: 15px !important;
}

.ag-sticky-bottom {
  display: none !important;
}

.ag-root-wrapper {
  overflow: visible;
}

.ag-root {
  overflow-y: visible;
  overflow-x: hidden;

  .ag-row-in-editing {
    .ag-cell {
      &:last-child {
        border-right: none;
      }
    }

    .br-none {
      border-right: none;
    }
  }

  .ag-cell-first-right-pinned,
  .ag-pinned-right-header {
    border-left: none !important;
  }

  .ag-cell,
  .ag-header-cell {
    margin-right: rem(24px);
    margin-left: 0;
    padding-left: rem(8px);
    padding-right: rem(8px);
    line-height: 39px;

    font-family: Inter;
    font-weight: 500;
    font-size: 13px;
  }

  .ag-header-cell {
    text-transform: uppercase;
  }

  .ag-cell-inline-editing {
    height: 38px;
    border: 0;
    padding: 0;
  }

  .ag-popup-editor {
    border: none;
    background: none;
    box-shadow: none;
  }
}

.ag-theme-material .ag-popup-editor {
  border: none;
  background: none;
  box-shadow: none !important;
  margin-top: 40px;
}

.ag-cell-popup-editing,
.ag-wrp-editing,
.ag-cell-inline-editing {
  // bug SC-276 with font-weight, need check ag-cell-popup-editing and ag-wrp-editing
  //font-weight: 500 !important;
  border-radius: 2px !important;
  border: 2px solid $primary !important;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 16%) !important;

  app-select-renderer {
    color: $primary;
  }

  .icon {
    color: $primary !important;
  }
}

.ag-theme-material {
  .grid-sort.sort-descending {
    transform: rotate(180deg);
  }

  .ag-input-field-input {
    padding: 0 !important;
  }

  .ag-row-selected {
    background: none;

    &::before {
      background: none !important;
    }
  }

  // scroll of right bar (3 dots editable component)
  .ag-horizontal-right-spacer {
    display: none;
  }
}

.booking-table-wrapper .ag-theme-material .ag-body-horizontal-scroll-container {
  margin-left: 60px;
}

.ag-horizontal-menu-item {
  color: $secondary;
}

.ag-horizontal-menu-item-red {
  color: $red-icon;
}

.ag-theme-material {
  .ag-row-selected {
    background: none;
  }
}

.ag-horizontal-menu-item {
  color: $secondary;
}

.ag-horizontal-menu-item-red {
  color: $red-icon;
}

.ag-theme-material .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-material .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-material .ag-layout-print .ag-center-cols-clipper,
.ag-theme-material .ag-layout-print .ag-center-cols-container {
  min-height: 0px !important;
}

.ag-center-cols-container {
  min-width: 100%;
}

//.ag-center-cols-container,
//.ag-pinned-right-cols-container {
//  .ag-row-not-inline-editing {
//    border: none!important;
//  }
//}

.ag-theme-material {
  overflow: hidden;
}

.ag-body-horizontal-scroll {
  width: 100%;
}

.note-panel {
  background: $brand-black;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  width: 240px;
  border-radius: 3px;

  &::after {
    content: "";
    position: absolute;
    left: calc(50% + -5px);
    width: 9px;
    height: 9px;
    transform: rotate(45deg);
    bottom: -4px;
    background: $brand-black;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  }

  .note-content {
    line-height: 18px;
  }
}

.select-renderer-panel {
  margin-right: -6px;
  left: -3px;
}

.kick-border-for-cell {
  border: none !important;
}

.ag-row-in-editing .border-save-cell {
  border: 2px solid $primary-dark !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;

  .grid-button-save {
    width: 36px;
  }
}
