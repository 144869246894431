@import "settings";

.app-select.mat-select {
  .mat-mdc-select-placeholder {
    color: rgba($brand-black, 0.3);
  }
}

.mat-mdc-select-arrow-wrapper,
.mat-select-arrow-wrapper {
  transform: none;

  .mat-mdc-select-arrow,
  .mat-select-arrow {
    border: none;
    margin: 0;
    height: 16px;
    width: 8px;
    background-image: url("../assets/icons/spinner.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
}

.mat-mdc-select-panel.default-mat-select-panel {
  margin-top: -10px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.client-requests-dropdown-panel {
  margin-top: 20px;
  margin-left: 5px;
}

.panel-class {
  margin-top: 24px;
  margin-left: -8px;
}

.studio-default-settings {
  margin-top: 30px;
  margin-left: 0;
}

.mat-select-panel {
  padding: 6px 0 !important;
}

.roles-chips-autocomplete.mat-mdc-autocomplete-panel {
  margin-top: -12px !important;

  .mat-mdc-option:not(.add-new-option):not(.warning-option):hover {
    padding: 0 !important;
  }
}

.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
  .mat-mdc-option:not(.add-new-option):not(.warning-option) {
    &:hover,
    &:active,
    &.mat-selected,
    &.mat-mdc-option-active,
    &.mdc-list-item--selected {
      color: $white !important;
      background: $primary !important;
      border-radius: 4px;
      padding: 0 8px !important;
      margin: 0 8px !important;

      .mdc-list-item__primary-text {
        color: $white !important;
      }
    }
  }

  &.sony-search-autocomplete .mat-mdc-option:not(.add-new-option):not(.warning-option):hover {
    padding: 0 !important;
  }
}

.mat-mdc-autocomplete-panel,
.mat-mdc-select-panel {
  padding: 0 !important;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f !important;

  .mat-mdc-option {
    @include normal-spacing;

    .mdc-list-item__primary-text {
      @include normal-spacing;
    }

    .mat-pseudo-checkbox {
      display: none !important;
    }
  }
}

.mat-mdc-form-field-type-mat-select {
  .mat-mdc-text-field-wrapper {
    &.mdc-text-field--outlined .mat-mdc-form-field-infix {
      margin: 0;
      padding: 8px 0;
      border: none;
      border-width: 0;
      min-height: 0;
    }

    &:not(.mat-mdc-form-field-disabled) {
      cursor: pointer;
    }
  }

  .mat-mdc-select-arrow-wrapper,
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-mdc-select-panel {
  .mdc-list-item__primary-text {
    display: inline-block;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
  }

  //.mat-mdc-option {
  //  padding: 0 !important;
  //}
}

.mat-mdc-select-value,
.mdc-list-item__primary-text {
  font-family: Inter, sans-serif !important;
  font-weight: 500 !important;
}

.mat-mdc-select-placeholder {
  color: rgba($brand-black, 0.3) !important;
  font-weight: 400 !important;
}

.address-country-select.mat-mdc-select-panel {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  min-width: 312px !important;

  .mat-mdc-option:not(.add-new-option):not(.warning-option) {
    margin: 0 !important;
    padding: 0 16px !important;

    .mdc-list-item__primary-text {
      color: $brand-black !important;
      font-family: Inter, sans-serif !important;
      font-weight: $fw-regular !important;
      font-size: 16px !important;
    }

    &.mat-mdc-option-active,
    &:hover {
      background: rgba($brand-black, 0.04) !important;

      .mdc-list-item__primary-text {
        color: $brand-black !important;
      }
    }

    &.mdc-list-item--selected .mdc-list-item__primary-text {
      color: $primary !important;
    }
  }

  &.discount-field {
    margin-bottom: 20px;
    min-width: 100% !important;
    .mat-mdc-option:not(.add-new-option):not(.warning-option) {
      font-size: 14px !important;
      .mdc-list-item__primary-text {
        font-size: 14px !important;
      }
    }
  }
}

.address-type-select.mat-mdc-select-panel {
  padding-top: 8px !important;
  padding-bottom: 8px !important;

  .mat-mdc-option:not(.add-new-option):not(.warning-option) {
    margin: 0 !important;
    padding: 0 16px !important;
    line-height: 3em !important;
    height: 36px !important;
    min-height: 36px !important;

    .mdc-list-item__primary-text {
      color: $brand-black !important;
      font-family: Inter, sans-serif !important;
      font-weight: $fw-regular !important;
      font-size: 12px !important;
    }

    &.mat-mdc-option-active,
    &:hover {
      background: rgba($brand-black, 0.04) !important;

      .mdc-list-item__primary-text {
        color: $brand-black !important;
      }
    }

    &.mdc-list-item--selected .mdc-list-item__primary-text {
      color: $primary !important;
    }
  }

  .mat-mdc-select {
    font-size: 12px !important;
  }
}

.company-type-select.mat-mdc-select-panel {
  margin-top: -8px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  min-width: 255px;
  width: 255px;

  .mat-mdc-option:not(.add-new-option):not(.warning-option) {
    margin: 0 !important;
    padding: 0 16px !important;
    line-height: 3em !important;
    height: 42px !important;
    min-height: 42px !important;

    .mdc-list-item__primary-text {
      color: $brand-black !important;
      font-family: Inter, sans-serif !important;
      font-weight: $fw-regular !important;
      font-size: 14px !important;
    }

    &.mat-mdc-option-active,
    &:hover {
      background: rgba($brand-black, 0.04) !important;

      .mdc-list-item__primary-text {
        color: $brand-black !important;
      }
    }

    &.mdc-list-item--selected .mdc-list-item__primary-text {
      color: $primary !important;
    }
  }

  .mat-mdc-select {
    font-size: 14px !important;
  }
}

.address-type-field {
  margin-left: 4px;

  .mdc-notched-outline__leading {
    border-top-style: solid !important;
    border-bottom-style: solid !important;
  }

  .mat-mdc-form-field-infix {
    padding: 0 !important;
    font-size: 12px !important;

    .mat-mdc-select {
      font-size: 12px !important;
      .mat-mdc-select-value {
        font-weight: $fw-regular !important;
      }
    }
  }
}
