@import "settings";

.sc-rich-text-editor {
  .sc-rich-text-editor-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;

    .sc-rich-text-editor-header-add-token {
      background-color: $white;
      border: 1px solid $mischka;
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba($black, 0.1);
      height: 24px;
      padding: 5px 10px;
      cursor: pointer;

      .mat-icon {
        width: 20px;
        height: 12px;
      }
    }
  }
  .sc-rich-text-editor-input {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    padding: 16px;
    line-height: 20px;
    background-color: $white;
    border: 1px solid $mischka;
    border-radius: 5px;
    outline: none;
    min-height: 58px;
    height: 256px;
    overflow-x: auto;

    span {
      color: $primary;
      background: rgba($primary, 0.2);
      border-radius: 4px;
      padding: 2px 0;
      white-space: nowrap;
      user-select: all;

      &:hover {
        color: $white;
        background: $primary;
      }

      &::after {
        content: "\200C";
      }
    }
  }

  &.sc-rich-text-editor-disabled {
    .sc-rich-text-editor-input {
      background-color: $disabled;
      cursor: default;
    }

    span {
      user-select: none;
    }
  }
}
