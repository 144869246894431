@mixin rem($properties, $values...) {
  @if type-of($properties) == "map" {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin stateElements($hover, $active) {
  &:hover {
    filter: brightness($hover);
  }

  &:active {
    filter: brightness($active);
  }
}

@mixin text-row-overflow-ellipsis($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin take-50-percent-in-flex {
  flex: 1 0 50%;
  min-width: 50%;
}

@mixin mat-mdc-font {
  font: {
    family: Inter, sans-serif;
    size: 14px;
    weight: $fw-medium;
  }
}

@mixin normal-spacing {
  letter-spacing: normal !important;
  word-spacing: normal !important;
}

@mixin text-overflow-ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
