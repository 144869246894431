@import "settings";

mat-button-toggle-group.sc-button-toggle-group {
  border: 0;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;

  .mat-button-toggle {
    flex: 1;
    color: $brand-black;
    font-size: 14px;
    font-weight: $fw-medium;
    background-color: $blue-embossing;
    padding: 4px;

    &.mat-button-toggle-disabled {
      color: rgba($black, 0.26);

      mat-icon {
        color: inherit;
      }
    }
  }

  .mat-button-toggle-button {
    display: flex;
    justify-content: center;
    height: 100%;
    border: 1px solid transparent;

    .mat-button-toggle-label-content {
      line-height: inherit;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .mat-button-toggle-checked {
    .mat-button-toggle-button {
      background-color: $white;
      border: 1px solid $mischka;
      border-radius: 8px;
      box-shadow: 0 1px 4px 0 $shadow;
    }
  }

  .mat-button-toggle + .mat-button-toggle {
    border-left: 0;
  }

  &.sc-button-toggle-group-with-opacity {
    .mat-button-toggle-button .mat-button-toggle-label-content {
      opacity: 0.5;
    }
    .mat-button-toggle-checked .mat-button-toggle-button .mat-button-toggle-label-content {
      opacity: 1;
    }
  }

  &.sc-button-toggle-group-large {
    .mat-button-toggle-button .mat-button-toggle-label-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      height: 100%;
      line-height: 20px;
      padding: 16px;
      gap: 4px;

      mat-icon,
      img {
        max-height: 20px;
        width: auto;
      }
    }
  }

  &.sc-button-toggle-group-medium {
    .mat-button-toggle {
      font-size: 10px;
    }
    .mat-button-toggle-button .mat-button-toggle-label-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      height: 100%;
      line-height: 20px;
      padding: 8px;
      gap: 4px;

      mat-icon,
      img {
        max-height: 16px;
        width: auto;
      }
    }
  }
}
