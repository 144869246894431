@import "settings";

.sc-link {
  color: $primary;
  font-size: 16px;
  font-weight: $fw-bold;
  line-height: 19px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
