@import "settings";

.auto-complete {
  max-height: 170px !important;
  min-width: 250px !important;
  border: 1px solid $mischka;
  border-radius: 8px !important;

  &.dynamic-auto-complete {
    max-width: 400px !important;
    width: auto;
  }

  .bottom-panel {
    border-top: 1px solid $mischka;
    height: 51px;
  }

  .wrapper {
    &.has-elements {
      padding: 8px 0;
    }
  }

  .mat-mdc-option {
    color: $brand-black;
    height: 40px;
    min-height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 500;
    font-family: Inter, sans-serif !important;

    .mat-pseudo-checkbox {
      display: none !important;
    }

    .mdc-list-item__primary-text {
      white-space: nowrap !important;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &.warning-option {
      .mdc-list-item__primary-text {
        width: 100%;
        line-height: 14px;
        flex-direction: column;
        justify-content: center;
      }
    }

    &.with-long-description {
      &:hover {
        .mdc-list-item__primary-text {
          display: inline;
          line-height: 1.5;
        }
      }
    }

    &.shadow-option {
      height: 0 !important;
      min-height: 0 !important;
    }

    &:hover:not(.warning-option):not(.add-new-option) {
      color: $white;
      background: $primary;
      margin: 0 8px;
      padding: 0 !important;
      border-radius: 4px;

      .mdc-list-item__primary-text {
        padding: 0 8px;
      }
    }
  }

  &.hide-dropdown {
    border: none;
  }

  &.app-badge-select-renderer-panel {
    min-width: fit-content !important;
    .with-long-description:hover {
      min-width: max-content;
    }
  }
}

.wide-option,
.option-right {
  overflow: hidden;
  text-overflow: ellipsis;
}

.option-right {
  opacity: 0.3;
}

.drop-time-renderer-select {
  max-height: 204px !important;
  span.mdc-list-item__primary-text {
    text-transform: lowercase;
  }
}
