@import "settings";

.sc-select-multi {
  max-width: rem(480px);

  select {
    border: rem(1px) solid $mischka;
    border-radius: rem(4px);
    font-size: rem(16px);
    line-height: rem(48px);

    option {
      padding: rem(4px);
    }
  }

  label {
    font-size: rem(16px);
    margin-bottom: rem(12px);
    line-height: rem(19px);
    font-weight: bold;

    &.small {
      font-size: rem(14px);
      line-height: rem(17px);
      margin-bottom: rem(3px);
    }
  }
}
