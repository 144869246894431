@import "settings";

.app-radio-group {
  .mdc-label {
    font-family: Inter, sans-serif !important;
    font-size: 16px;
    letter-spacing: normal;
  }

  .mat-mdc-radio-button {
    margin-bottom: 10px;

    .mdc-radio {
      padding: 0 !important;
      top: 2px !important;
    }

    .mdc-radio__background::before {
      display: none;
    }

    .mat-ripple {
      display: none;
    }

    .mat-radio-container {
      width: 16px;
      height: 16px;
    }

    .mat-radio-label-content {
      font-size: 14px;
      font-weight: $fw-medium;
    }

    .mdc-radio__inner-circle {
      display: none;
    }

    .mdc-radio__outer-circle {
      background-color: $white;
      border: 1px solid $mischka !important;
      border-radius: 100%;
      box-shadow: 0 1px 4px 0 $shadow;
      width: 16px;
      height: 16px;
    }

    .mdc-radio--disabled {
      .mdc-radio__outer-circle {
        opacity: 1 !important;
        border: 1px solid $mischka !important;
        box-shadow: 0 1px 4px 0 $shadow;
      }
    }

    &.mat-mdc-radio-checked {
      .mdc-radio__outer-circle {
        border-color: $primary !important;
        border-width: 5px !important;
      }

      .mdc-radio--disabled {
        .mdc-radio__outer-circle {
          opacity: 1 !important;
          border-color: $mischka !important;
        }
      }
    }
  }
}
