@media print {
  @page {
    margin: 0;
    size: auto;
  }

  html,
  body,
  .app-container {
    height: 99% !important;
  }

  body {
    width: 100% !important;
    background-color: white;
    overflow: visible;
    box-sizing: border-box;
    padding: 0 0.9cm !important;
    margin: 0 !important;
  }

  * {
    overflow: visible !important;
    color: black !important;
  }

  .d-flex {
    display: block !important;
  }

  .card-primary {
    box-shadow: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
  }

  .app-container {
    width: 100% !important;
    background-color: white !important;
    min-width: 100% !important;
  }

  .app-scroll-container,
  .ag-root-wrapper {
    overflow: visible !important;
  }

  app-sidenav,
  app-root,
  .fixed-header,
  .create-section,
  .mat-tab-nav-bar,
  .invoice-details-top-bar,
  .mat-mdc-tab-header,
  .mat-mdc-tab-list,
  .mat-mdc-snack-bar-container,
  .ag-pinned-left-cols-container,
  .grid-with-divider-menu .ag-row.ag-full-width-row,
  .ag-pinned-left-header,
  .stamp-holder,
  .no-print,
  .functional-buttons,
  .cdk-visually-hidden {
    display: none !important;
    size: auto;
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
  }

  .subroutes-wrapper,
  section {
    margin: 0 !important;
    padding: 0 !important;
  }

  app-invoice-studio-time-table,
  app-invoice-assistant-time-table,
  app-invoice-engineer-time-table,
  app-invoice-extras-time-table {
    display: block;
    page-break-inside: avoid !important;
  }

  .ag-root {
    display: table !important;
    table-layout: fixed !important;
    width: 100% !important;
    page-break-inside: avoid !important;

    * {
      width: auto !important;
      max-width: none !important;
      min-width: none !important;
      height: auto !important;
    }

    .ag-body-viewport {
      * {
        height: auto !important;
        min-height: auto !important;
        white-space: normal !important;
        word-break: break-all !important;
      }
    }

    .ag-center-cols-container,
    .ag-center-cols-viewport {
      width: 100% !important;
      height: auto !important;
      white-space: normal !important;
      word-break: break-all !important;
    }

    .ag-header,
    .ag-body-viewport,
    .ag-header-container,
    .ag-center-cols-container,
    .ag-header-viewport {
      height: auto !important;
      width: 100% !important;
      white-space: normal !important;
      word-break: break-all !important;
      page-break-inside: avoid !important;
    }

    .ag-row,
    .ag-header-row,
    .ag-header {
      width: 100% !important;
      display: table !important;
      table-layout: fixed !important;
      height: auto !important;
      page-break-inside: avoid !important;
      position: static !important;
      transform: none !important;
      border-bottom: 0;
      overflow: visible !important;
    }

    .ag-header-row {
      border-top: 1px solid $mischka !important;
      border-bottom: 1px solid $mischka !important;
      margin: -1px 0;
    }

    .ag-row {
      border-top: 1px solid $mischka !important;
      border-bottom: 1px solid $mischka !important;
      margin-bottom: -1px;
    }

    .ag-cell-label-container {
      padding: 0 !important;
    }

    .ag-cell,
    .ag-header-cell {
      display: table-cell !important;
      border: 0 !important;
      line-height: 21px !important;
      padding: 10px 5px 10px 0 !important;
      vertical-align: middle;
    }

    .ag-pinned-right-header,
    .ag-pinned-right-cols-container,
    .ag-tab-guard {
      display: none !important;
      height: auto !important;
    }

    .date,
    .sc-input,
    .ag-full-width-container,
    .h-100 {
      height: auto !important;
      min-width: auto !important;
      min-height: auto !important;
    }

    .sc-input {
      padding: 0 !important;
    }
  }

  .invoice-details {
    padding: 0 !important;

    .title {
      border: 0 !important;
    }

    h1 {
      margin-bottom: 40px !important;
    }

    .table-total {
      padding: 20px 10px 0 0 !important;
    }

    .tables-holder .table-total {
      padding: 10px 10px 0 0 !important;
    }

    .overview-header {
      display: block !important;
      margin-bottom: 5px !important;
      overflow: hidden !important;
    }

    .address-container {
      padding: 0;
      width: 30% !important;
      float: left;
    }

    .overview-info-container {
      width: 70% !important;
      padding-left: 0;
      float: right;
    }

    .total-container {
      margin: 35px 0 0 !important;
      padding: 15px 0 !important;
      page-break-inside: avoid;
      display: block !important;
      overflow: hidden !important;

      .amount-column {
        float: left;
        width: 55%;
      }

      .total-column {
        float: right;
        width: 45%;
        padding: 0 10px !important;

        &:after {
          right: 165px !important;
        }
      }

      .right-aligned {
        min-width: 180px !important;
        max-width: 0 !important;
      }
    }
  }

  .content-holder {
    padding: 0 !important;
    margin: 0 !important;
  }

  .tables-holder {
    margin: 0;
    padding: 0;
    border: 0 !important;
  }

  .printable-table {
    border-collapse: collapse;
    width: 100%;
  }

  .printable-header,
  .header-space {
    height: 30px;
  }

  .footer-space {
    height: 30px;
  }

  .printable-footer {
    height: 30px;
  }

  .title-wrapper {
    padding-top: 20px !important;
  }

  .footer-text {
    color: #9ca3af;
    font: 400 12px/16px Inter, sans-serif;
  }

  .printable-header {
    position: fixed;
    top: 0;
    left: 0.75cm;
    right: 0.75cm;
  }

  .printable-footer {
    position: fixed;
    text-align: center;
    bottom: 0;
    left: 0.75cm;
    right: 0.75cm;
  }

  .save-mode-template {
    padding: 0 !important;
  }

  textarea {
    width: 100% !important;
  }
}
