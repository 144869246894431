@import "settings";

.menu-panel {
  background-color: $white;
  border: 1px solid $mischka;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  min-height: auto !important;

  button.mat-mdc-menu-item {
    text-align: right;
    height: 30px;
    line-height: 30px;
    justify-content: flex-end;
  }
}
