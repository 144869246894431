@import "settings";

$base-selected-item-color: var(--base-selected-item-color);

.sc-multiselect {
  position: relative;
  width: 100%;

  &.sc-multiselect-disabled {
    cursor: default;
    pointer-events: none;

    .sc-multiselect-add-button {
      color: $mischka;
    }

    .sc-multiselect-selected-item__remove-icon {
      display: none;
    }
  }

  .sc-multiselect-selected-items {
    display: flex;
    flex-direction: column;
    padding: 4px;
    border: 1px solid $mischka;
    border-bottom: 0;
    border-radius: 8px 8px 0 0;
    gap: 4px;

    .sc-multiselect-selected-item {
      --base-selected-item-color: 0, 0, 0; // black
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 32px;
      padding: 8px;
      font-size: 14px;
      font-weight: $fw-bold;
      border-radius: 4px;
      background: linear-gradient(90deg, rgba($base-selected-item-color, 0.2) 0%, rgb(255, 255, 255) 50%);

      &:hover {
        cursor: default;
      }

      .sc-multiselect-selected-item__label {
        color: rgb($base-selected-item-color);

        &:before {
          content: "";
          display: inline-block;
          border-radius: 16px;
          width: 8px;
          height: 8px;
          background-color: rgb($base-selected-item-color);
          margin-right: 4px;
        }
      }

      .sc-multiselect-selected-item__remove-icon {
        cursor: pointer;
        width: 12px;
        height: 14px;
        color: $scarlet;
      }

      &.sc-multiselect-selected-item-mist {
        background: white !important;

        .sc-multiselect-selected-item__label {
          color: $black !important;

          &:before {
            background-color: $black !important;
          }
        }
      }
    }
  }

  .sc-multiselect-selected-items + .sc-multiselect-add-button,
  .sc-multiselect-selected-items + .sc-multiselect-control .ng-select-container {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .sc-multiselect-add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 8px;
    border: 1px dashed $mischka;
    border-radius: 8px;
    font-size: 14px;
    font-weight: $fw-bold;
    color: $gray-chateau;

    &:hover {
      cursor: pointer;
    }
  }

  .sc-multiselect-control {
    &.sc-multiselect-control-hide {
      position: absolute;
      top: 0;
      z-index: -1;
      width: 100%;
      opacity: 0;
    }

    &.ng-select {
      &:not(.ng-select-filtered) .ng-has-value .ng-placeholder {
        display: block !important;
      }

      .ng-value {
        display: none !important;
      }

      .ng-placeholder {
        padding-left: 13px !important;
      }

      .ng-select-container .ng-value-container .ng-input > input {
        padding-left: 6px;
      }

      .ng-optgroup {
        .ng-option-label {
          font-size: 12px;
          font-weight: $fw-bold;
        }
      }
    }
  }
}

.sc-multiselect-control {
  .ng-option {
    &.ng-option-disabled {
      color: $grey-80 !important;
    }
    &.ng-option-marked:not(.ng-option-disabled) {
      .color-scarlet,
      .color-gray-chateau {
        color: $white !important;
      }
    }
  }
}
