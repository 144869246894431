@import "settings";

:host {
  .scroll-container {
    height: 96vh;
    overflow-y: auto;
  }

  ::ng-deep {
    .cal-week-view {
      border: none;

      mwl-calendar-week-view-header {
        position: sticky;
        display: block;
        z-index: 9;
        background: $white;
        top: 0;
      }

      .cal-day-headers {
        padding-left: 50px;
        border: none;
        border-bottom: 1px solid $mischka;
        box-shadow: 0 3px 2px -2px $shadow;
        height: 90px;

        b {
          color: $gray-chateau;
          font-size: 11px;
          font-weight: $fw-medium;
          line-height: 13px;
          text-align: left;
          opacity: 0.5;
        }

        span {
          color: $brand-black;
          font-size: 20px;
          font-weight: $fw-medium;
          line-height: 24px;
          text-align: left;
          opacity: 1;
        }

        .cal-header {
          text-align: left;
          border-color: transparent;
          margin-bottom: -1px;
          align-self: flex-end;

          &:hover {
            background: none;
          }

          &:focus {
            outline: none;
          }

          &.cal-today {
            background: none;
            border-bottom: 1px solid $primary;

            b,
            span {
              color: $primary;
            }
          }
        }
      }

      .cal-current-time-marker {
        background-color: $primary;
        color: $primary;

        .time {
          position: absolute;
          left: -35px;
          font-size: 12px;
          font-weight: 500;
        }
      }

      .cal-event {
        border-radius: 3px;
        border: none;
        border-left: 3px solid;
        outline: none;
        color: $brand-black;
      }

      .cal-event-title {
        white-space: normal;
        color: $brand-black;
        font-size: 12px;
        font-weight: $fw-semi-bold;
        line-height: 14px;
        display: block;
        margin-top: 5px;
      }

      .color-text-black .cal-event-title {
        color: $brand-black;
      }

      .color-text-red .cal-event-title {
        color: $warning-red;
      }

      .color-text-white .cal-event-title {
        color: $white;
      }

      .cal-all-day-events {
        position: sticky;
        top: 90px;
        z-index: 9;
        background: $white;
        border: none;
        box-shadow: 0 1px 3px 0 $shadow;

        .cal-time-label-column {
          box-shadow: -3px 0px 0px 0 $white;
        }

        .cal-day-columns .cal-day-column:nth-child(2) {
          border-left-color: $white;
        }

        .cal-events-row {
          margin-left: 50px;
        }
      }

      .cal-day-columns {
        border-bottom: 1px solid $calendar-border-gray-day;

        .bg-current-day {
          background-color: $calendar-bg-current-day !important;
        }

        .bg-weekend {
          background-color: $calendar-bg-weekend !important;
        }

        .cal-hour:not(:last-child) .cal-hour-segment,
        .cal-hour:last-child :not(:last-child) .cal-hour-segment {
          border-bottom-color: $calendar-border-gray;
          border-bottom-style: solid;

          &.cal-hour-start {
            border-bottom-color: $calendar-border-gray-hour-start;
          }
        }
      }

      .cal-hour-odd {
        background: none;
      }

      .cal-time-events {
        border: none;

        .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
          background-color: transparent;
        }

        .cal-events-container {
          width: calc(100% - 10px);
        }

        mwl-calendar-event-actions {
          display: none;
        }
      }

      .cal-time-label-column {
        width: 50px;

        & .cal-hour:first-child .cal-time {
          top: 0;
        }

        .cal-hour:last-child :not(:last-child) .cal-hour-segment,
        .cal-hour-segment {
          border-color: transparent;
        }

        .cal-time {
          width: 50px;
          padding-top: 0;
          position: absolute;
          top: -10px;
          color: $gray-chateau;
          font-size: 12px;
          font-weight: $fw-medium;
        }
      }
    }
  }
}
