@import "settings";

h1 {
  color: $brand-black !important;
  font-size: 32px !important;
  line-height: 38px;
  font-weight: $fw-black;
}

h2 {
  color: $brand-black !important;
  font-size: 24px;
  line-height: 29px;
  border: none !important;
  font-weight: $fw-black;
}

h3 {
  color: $brand-black !important;
  font-size: 20px;
  line-height: 24px;
  font-weight: $fw-black;
}

h4 {
  color: $brand-black !important;
  font-size: 16px;
  line-height: 19px;
  font-weight: $fw-black;
}

.title-text {
  color: $brand-black !important;
  font-weight: $fw-bold !important;
  font-size: 24px !important;
  line-height: 24px !important;
}

.small-text {
  color: $brand-black !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.micro-text {
  color: $brand-black !important;
  font-size: 12px;
  line-height: 14px;
}

.nano-text {
  color: $brand-black !important;
  font-size: 11px;
  line-height: 13px;
}

.widget-title-text {
  color: $brand-black !important;
  font-size: 19px;
  line-height: 23px;
}

.fw-thin {
  font-weight: $fw-thin;
}

.fw-extra-light {
  font-weight: $fw-extra-light;
}

.fw-light {
  font-weight: $fw-light;
}

.fw-regular {
  font-weight: $fw-regular !important;
}

.fw-medium {
  font-weight: $fw-medium !important;
}

.fw-semi-bold {
  font-weight: $fw-semi-bold;
}

.fw-bold {
  font-weight: $fw-bold;
}

.fw-extra-bold {
  font-weight: $fw-extra-bold;
}

.fw-black {
  font-weight: $fw-black !important;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-32 {
  font-size: 32px;
}

.fs-40 {
  font-size: 40px;
}

.fs-italic {
  font-style: italic;
}

.text-center {
  text-align: center;
}

.lh-12 {
  line-height: 12px;
}

.lh-14 {
  line-height: 14px;
}

.lh-16 {
  line-height: 16px;
}

.lh-17 {
  line-height: 17px;
}

.lh-18 {
  line-height: 18px;
}

.lh-19 {
  line-height: 19px;
}

.lh-20 {
  line-height: 20px;
}

.lh-22 {
  line-height: 22px;
}

.lh-24 {
  line-height: 24px;
}

.lh-29 {
  line-height: 29px;
}

.lh-30 {
  line-height: 30px;
}

.lh-36 {
  line-height: 36px;
}

.lh-38 {
  line-height: 38px !important;
}

.lh-40 {
  line-height: 40px;
}

.break-all {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

.ws-nowrap {
  white-space: nowrap;
}

.text-uppercase {
  text-transform: uppercase;
}
